import { Col,Row, Container, Card } from "react-bootstrap";

export const ShowCoordinates = () => {
  const coordinatesData = localStorage.getItem("coordinates");
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={20}>
          <Card className="mt-2">
            <Card.Body>
              <Card.Title>JSON Viewer</Card.Title>
              <pre
                style={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                  fontSize: "10px",
                }}
              >
                {JSON.stringify(coordinatesData, null, 2)}
              </pre>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
