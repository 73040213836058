import React, { useState, useEffect } from "react";
import { Col, Card, NavItem, Alert } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import SlideButton from "react-slide-button";
import { toast } from "react-toastify";
import axios from "axios";
import DriverMap from "../components/DriverMap";
import config from "../config";
import { getStoredUser } from "../utils/localStorageUtils";
// import images
import profilePhoto from "../images/profileDP.jpg";
import callIcon from "../images/callIcon.svg";
import userIcon from "../images/userIcon.svg";
import breifcaseIcon from "../images/breifcaseIcon.svg";
import watchTimeIcon from "../images/watchTimeIcon.svg";
import kmsIcon from "../images/kmsIcon.svg";

import bultIcon from "../images/bult.svg";
import mapIcon from "../images/mapIcon.svg";
import arroweast from "../images/arroweast.svg";
import DriverFooter from "../components/DriverFooter";


const MyJob = () => {
  const navigate = useNavigate();
  const [reset, setReset] = useState(0);
  const storedUser = getStoredUser();
  // const { currency, t } = props;
  const params = useParams();
  const { code } = params;

  const [modalOpen, setModelOpen] = useState(false);
  const [pinModalOpen, setPinModalOpen] = useState(false);
  const [location, setLocation] = useState({});
  const [payloadData, setPayloadData] = useState({});
  const [clicked, setClicked] = useState(false);
  const [shouldReturn, setShouldReturn] = useState(false);
  const [directions, setDirections] = useState({});
  const [driveStart, setDriveStart] = useState(
    localStorage.getItem("driveStart")
      ? localStorage.getItem("driveStart")
      : false
  );
  const [journeyStartData, setJourneyStartData] = useState({});
  const [journeyId, setJourneyId] = useState(
    localStorage.getItem("journeyId") ? localStorage.getItem("journeyId") : ""
  );
  const [destination, setDestination] = useState(
    localStorage.getItem("destination")
      ? localStorage.getItem("destination")
      : ""
  );
  const [driverExecuteData, setDriverExecuteData] = useState({});
  const [driverArriving, setDriverArriving] = useState(
    localStorage.getItem("driverArriving")
      ? localStorage.getItem("driverArriving")
      : false
  );
  const [enableLocation, setEnableLocation] = useState(false);
  useEffect(() => {
    const progress = localStorage.getItem(
      `progress-${driverExecuteData?.bookingId}`
    );
    if (progress == "3") navigate(`/customer-on-board-or-not/${code}`); // Redirect if Step 1 not completed
  }, [driverExecuteData, navigate]);

  const getDriverExecute = async () => {
    const response = await axios.get(`${config.getJobDetailUrl(code)}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Token":
          "4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585",
        Authorization: `Bearer ${storedUser?.token.access_token}`,
      },
    });
    if (response?.status === 200) {
      setDriverExecuteData(response?.data?.data);
      setPayloadData({
        ...payloadData,
        booking_id: response?.data?.data.bookingId,
      });
      localStorage.setItem("driveInfo", JSON.stringify(response?.data?.data));
    }
  };
  const date = new Date();

  // Adjust timezone to +03:00
  const offset = 3 * 60; // +03:00 in minutes

  // Convert the date to ISO string but without the Z (UTC indicator)
  const localISOTime = new Date(date.getTime() + offset * 60000)
    .toISOString()
    .slice(0, 16);

  // Add the desired timezone offset manually
  const formattedDate = `${localISOTime}+03:00`;
  useEffect(() => {
    getDriverExecute();
  }, [code]);
  // API for move on Step 2
  const configJourneyStart = {
    method: "POST",
    url: `${config.JourneyStart}`,
    data: {
      ...payloadData,
      amAtLocationPoint: `${location.lat}, ${location.long}`,
      amAtLocationDateTime: formattedDate,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
      "Access-Token":
        "4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585",
      Authorization: `Bearer ${storedUser?.token.access_token}`,
    },
  };
  const onSubmitJourneyStart = async () => {
    const journeyStartResp = await axios(configJourneyStart).catch((e) => {});
    if (journeyStartResp == undefined) {
      toast.error("Something wrong");
      setReset(true);
    } else {
      if (journeyStartResp?.status === 200) {
        setJourneyStartData(journeyStartResp?.data?.data);
        setDriveStart(true);
        localStorage.setItem("driveStart", "true");
        setShouldReturn(false);
        setClicked(false);
        toast.success(
          journeyStartResp?.data?.message || "Journey Start Successfully"
        );
        setJourneyId(journeyStartResp?.data?.data?.journeyId);
        localStorage.setItem(
          "journeyId",
          journeyStartResp?.data?.data?.journeyId
        );
        localStorage.setItem(`progress-${driverExecuteData?.bookingId}`, "2");
        navigate(`/arrived-at-pickup/${code}`);
      } else {
        toast.error("Something wrong");
        setReset(true);
      }
    }
  };
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("Latitude is :", latitude, "Longitude is :", longitude);
          setLocation({ lat: latitude, long: longitude });
          setEnableLocation(true);
          const coordinates = { latitude: latitude, longitude: longitude };
          localStorage.setItem("coordinates", JSON.stringify(coordinates));
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            // alert("Location access is required. Please enable location permissions in your browser settings.");
            setEnableLocation(false);
          } else {
            console.error("Error getting location:", error);
            setEnableLocation(false);
          }
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setEnableLocation(false);
    }
  }, []);
  return (
    <>
      <div
        style={{ backgroundColor: "#F3F3F3" }}
        className="pb-4 position-relative"
      >
        <section className="myJob secondaryBG position-relative">
          <Col
            xxl={"9"}
            xl={"9"}
            lg={"10"}
            md={"11"}
            className="d-flex align-items-center justify-content-between col-11 mx-auto"
          >
            <h4 className="mainText fw-bold" style={{ fontSize: "22px" }}>
              My Job
            </h4>
            {/* <img className='img-fluid me-2' width={'100px'} src={driverExecuteData?.carImage} alt='icon' /> */}
          </Col>
        </section>
        <section className="myJob py-0" style={{ marginBottom: "78px" }}>
          <Col
            xxl={"9"}
            xl={"9"}
            lg={"10"}
            md={"11"}
            className="col-11 mx-auto"
          >
            <Card
              className="whiteBG"
              style={{
                borderColor: "#fff",
                borderRadius: "30px",
                boxShadow: "0px 0px 5px 0px #DDDDDD",
              }}
            >
              <Card.Body>
                <div className="d-flex align-items-center mb-lg-5 mb-4">
                  <Link to={"#"} className="d-flex align-items-center">
                    <div className="profilePicture me-3">
                      <img
                        className="img-fluid"
                        src={profilePhoto}
                        alt="image"
                      />
                    </div>
                    <div className="">
                      <h4 className="mb-2 title">
                        {driverExecuteData?.passengerName || ""}
                      </h4>
                      <p
                        className="mb-0"
                        style={{
                          fontSize: "14px",
                          lineHeight: "16px",
                          fontWeight: "300",
                        }}
                      >
                        {" "}
                        {driverExecuteData?.vehicleCategory || ""}{" "}
                        {/*driverExecuteData?.vehicleDetails || "" */}
                      </p>
                    </div>
                  </Link>
                  <div className="ms-auto text-end">
                    +{driverExecuteData?.passengerMobile}{" "}
                    <img
                      className="img-fluid"
                      onClick={() => {
                        if (driverExecuteData) {
                          window.open(
                            `tel:${driverExecuteData.passengerMobile}`
                          );
                        }
                      }}
                      src={callIcon}
                      alt="img"
                    />
                    <h5 className="title">
                      #{driverExecuteData?.bookingId || ""}
                    </h5>
                  </div>
                </div>
                {/*  */}
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <Link to="#" className="">
                    <img className="img-fluid me-2" src={userIcon} alt="icon" />{" "}
                    {driverExecuteData?.passengers || ""}
                  </Link>
                  <Link to="#" className="">
                    <img
                      className="img-fluid me-2"
                      src={breifcaseIcon}
                      alt="icon"
                    />{" "}
                    {driverExecuteData?.luggage || ""}
                  </Link>
                  <Link to="#" className="">
                    <img
                      className="img-fluid me-2"
                      src={watchTimeIcon}
                      alt="icon"
                    />{" "}
                    {driverExecuteData?.duration || ""}
                  </Link>
                  <Link to="#" className="">
                    <img className="img-fluid me-2" src={kmsIcon} alt="icon" />{" "}
                    {driverExecuteData?.distance || ""} Kms
                  </Link>
                </div>
                {/*  */}
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0">Date & Time</p>
                  <p className="mb-0 secondaryText">
                    {driverExecuteData.arrival_human_date || ""} |
                    {driverExecuteData?.arrival_time}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0">Flight No</p>
                  <p className="mb-0 secondaryText">
                    {driverExecuteData.flightNo || ""}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0">Special Instructions</p>
                  <p className="mb-0 secondaryText">
                    {driverExecuteData.specialInstructions || "N/A"}
                  </p>
                </div>
                <hr />
                <div className="mx-auto col-11 py-4 designLine">
                  <div className="circleIcon position-relative">
                    <NavItem
                      className="d-flex align-items-center mb-4 justify-content-center position-relative"
                      style={{ zIndex: "1" }}
                    >
                      <div className="col-12 d-flex align-items-center">
                        <div
                          className="me-3 w-100 h-100 d-flex align-items-center justify-content-center mb-lg-0 mb-3"
                          style={{
                            borderRadius: "100px",
                            background: "#C8D5E8",
                            minHeight: "60px",
                            maxWidth: "60px",
                            minWidth: "60px",
                            maxHeight: "60px",
                          }}
                        >
                          <img
                            className="img-fluid"
                            src={bultIcon}
                            alt="image"
                          />
                        </div>
                        <div className="">
                          <h5
                            className="mb-0"
                            style={{ fontSize: "18px", fontWeight: "600" }}
                          >
                            Pick Up Location
                          </h5>
                          <p className="mb-0" style={{ fontSize: "14px" }}>
                            {driverExecuteData?.pickup || ""}
                          </p>
                        </div>
                      </div>
                    </NavItem>
                    {/*  */}
                    <NavItem
                      className="d-flex align-items-center mb-4 justify-content-center position-relative"
                      style={{ zIndex: "1" }}
                    >
                      <div className="col-12 d-flex align-items-center">
                        <div
                          className="me-3 w-100 h-100 d-flex align-items-between justify-content-center mb-lg-0 mb-3"
                          style={{
                            borderRadius: "100px",
                            background: "#C8D5E8",
                            minHeight: "60px",
                            maxWidth: "60px",
                            minWidth: "60px",
                            maxHeight: "60px",
                          }}
                        >
                          <img
                            className="img-fluid"
                            src={mapIcon}
                            alt="image"
                          />
                        </div>
                        <div className="">
                          <h5
                            className="mb-0"
                            style={{ fontSize: "18px", fontWeight: "600" }}
                          >
                            Drop off Location
                          </h5>
                          <p className="mb-0" style={{ fontSize: "14px" }}>
                            {driverExecuteData?.destination || ""}
                          </p>
                        </div>
                      </div>
                    </NavItem>
                  </div>
                </div>
                {/* Map Area */}
                <div className="py-1 h-80 w-full mb-3 overflow-hidden">
                  {driverExecuteData?.pickupLatLong ? (
                    <DriverMap
                      defaultLocation={{
                        lat: Number(location.lat),
                        lng: Number(location.long),
                      }}
                      destination={{
                        lat: Number(driverExecuteData?.destinationLatLong?.lat),
                        lng: Number(
                          driverExecuteData?.destinationLatLong?.long
                        ),
                      }}
                      origin={{
                        lat: Number(driverExecuteData?.pickupLatLong?.lat),
                        lng: Number(driverExecuteData?.pickupLatLong?.long),
                      }}
                    />
                  ) : null}
                </div>
                {/*  */}
                {enableLocation ? (
                  <SlideButton
                    mainText="On The Way"
                    overlayText=""
                    overlayClassList="custom-width"
                    classList="mainText"
                    caretClassList="custom-caret-list"
                    reset={reset}
                    caret={
                      <p className="mb-0" style={{ color: "white" }}>
                        <img
                          className="img-fluid"
                          style={{ width: "25px" }}
                          src={arroweast}
                          alt="icon"
                        />
                      </p>
                    }
                    customCaretWidth={55}
                    onSlideDone={onSubmitJourneyStart}
                  />
                ) : (
                  <Alert key="danger" variant="danger">
                    Location access is required. Please enable location
                    permissions in your browser settings to continue.
                  </Alert>
                )}
              </Card.Body>
            </Card>
          </Col>
        </section>
        {/*  */}
        <DriverFooter />
      </div>
    </>
  );
};

export default MyJob;
