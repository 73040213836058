import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Swiper, SwiperSlide } from 'swiper/react';
import CryptoJS from 'crypto-js';
import AOS from 'aos';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import config from '../config';
import Loading from '../components/Loading';
import { handleApiError } from '../utils/errorHandler';
// import component
import ExperienceUL from '../components/ExperienceUL';
import CardsprojectsUL from '../components/CardsprojectsUL';
import Search from '../components/Search.jsx';
// import image
import infoImg from '../images/infoImg.png';
import infoImg1 from '../images/infoImg1.png';
// import card0 from '../images/card0.png';
import serviceIMG_01 from '../images/card1.jpg';
import IMGLogo from '../images/IMGLogo.png';
import serviceIMG_02 from '../images/card2.jpg';
import serviceIMG_03 from '../images/card3.jpg';
import serviceIMG_04 from '../images/card4.jpg';
import serviceIMG_05 from '../images/card5.jpg';
import serviceIMG_06 from '../images/card6.png';
import serviceIMG_07 from '../images/card7.jpg';



// 
const CardsprojectsULArray = [
  {
    key: "0",
    serviseIMG: serviceIMG_01,
    cardColor: 'rgb(5 15 5)',
    serviseIMGLogo: IMGLogo,
  },
  {
    key: "1",
    serviseIMG: serviceIMG_02,
    cardColor: '#330000',
    serviseIMGLogo: IMGLogo,
  },
  {
    key: "2",
    serviseIMG: serviceIMG_03,
    cardColor: '#000033',
    serviseIMGLogo: IMGLogo,
  },
  {
    key: "3",
    serviseIMG: serviceIMG_04,
    cardColor: '#330033',
    serviseIMGLogo: IMGLogo,
  },
  {
    key: "4",
    serviseIMG: serviceIMG_05,
    cardColor: 'rgb(5 15 5)',
    serviseIMGLogo: IMGLogo,
  },
  {
    key: "5",
    serviseIMG: serviceIMG_06,
    cardColor: '#330000',
    serviseIMGLogo: IMGLogo,
  },
  {
    key: "6",
    serviseIMG: serviceIMG_07,
    cardColor: '#000033',
    serviseIMGLogo: IMGLogo,
  },
]


// optionsprojects
const optionsprojects = {
  spaceBetween: 50,
  modules: [Autoplay],
  breakpoints: {
    400: {
      slidesPerView: 1,
    },
    600: {
      slidesPerView: 2,
    },
    700: {
      slidesPerView: 3,
    },
    1000: {
      slidesPerView: 4,
    },
    1300: {
      slidesPerView: 4,
    },
    1500: {
      slidesPerView: 5,
    },
  },
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  loop: true,
};

// cardsprojects
const cardsprojects = {
  spaceBetween: 30,
  modules: [Autoplay],
  breakpoints: {
    400: {
      slidesPerView: 1,
    },
    600: {
      slidesPerView: 2,
    },
    700: {
      slidesPerView: 3,
    },
    1000: {
      slidesPerView: 4,
    },
    1300: {
      slidesPerView: 4,
    },
    1500: {
      slidesPerView: 6,
    },
  },
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  loop: true,
};

const Home = () => {
  const [isLoading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    setLoading(true);
    const fetchdata = async () => {
      const configs = await axios.get(`${config.configUrl}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585'
        }});
        // function decryptMessage(encryptedData, key) {
        //   const { ciphertext, iv } = encryptedData;
          
        //   // Decode base64 to get ciphertext and IV
        //   const decodedCiphertext = CryptoJS.enc.Base64.parse(ciphertext);
        //   const decodedIV = CryptoJS.enc.Base64.parse(iv);
        
        //   // Decrypt using AES
        //   const decrypted = CryptoJS.AES.decrypt(
        //     { ciphertext: decodedCiphertext }, 
        //     CryptoJS.enc.Utf8.parse(key),
        //     { iv: decodedIV, mode: CryptoJS.mode.CFB }
        //   );
        
        //   return decrypted.toString(CryptoJS.enc.Utf8);
        // }
        
        // const encryptedData = { 
        //   ciphertext: '<base64_encrypted_data>',
        //   iv: '<base64_iv>'
        // };
        
        // const key = '706df369f4487e49cf8f22ea629ebdf2a76cfe71816216d307688826cbc2b1d9';
        // const decryptedMessage = decryptMessage(encryptedData, key);
        // console.log(decryptedMessage); 
      try {
        const result = await axios.get(`${config.CitiesFilter('limit', 15)}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585'
          }
        });
        if (result?.data) {
          setCities(result.data);
        } else {
          setCities([]);
        }
      } catch (error) {
        handleApiError(error);
        setCities([]);
      } finally {
        setLoading(false); // reset loading state
      }
    };
    fetchdata();
    window.scrollTo(0, 0);
    AOS.init();
  }, []);
  const GetCitiesByContinent = async (continent) => {
    try {
      if(continent === null || continent === ''){
          continent = 'popular'; 
      }
      const result = await axios.get(continent === 'popular' ? `${config.CitiesFilter(continent, 'true')}` : `${config.CitiesFilter('continent', continent)}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585'
        }
      });
      if (result?.data) {
        setCities(result.data);
      } else {
        setCities([]);
      }
    } catch (error) {
      handleApiError(error);
      setCities([]);
    } finally {
      setLoading(false); // reset loading state
    }
  }
  return (
    <main>
      <Helmet>
        <title>Book Private Taxi & Airport Transfers Worldwide - Limowide</title>
        <link rel="canonical" href="https://limowide.com/" /> 
        <meta name="description" content="Limowide is a global platform for ground transportation, providing private taxi, airport transfers, and city tours in over 1,000 cities worldwide." />
        <meta name="keywords" content="taxi in oslo, taxi bologna, prague limo service, hammerfest taxi, airport transfer bergen, airport transfer, airport Taxi, Limowide"></meta>
        <meta property="og:title" content="Book Private Taxi & Airport Transfers Worldwide" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="WebPage" />
        <meta property="og:description" content="Limowide is a global platform for ground transportation, providing private taxi, airport transfers, and city tours in over 1,000 cities worldwide." />
        <meta property="og:image" content="https://sandbox.limowide.com/static/media/logo.ac9490d8ab0c66d9e30d.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="https://limowide.com/" /> 
        <meta name="twitter:title" content="Book Private Taxi & Airport Transfers Worldwide" />
        <meta name="twitter:description" content="Limowide is a global platform for ground transportation, providing private taxi, airport transfers, and city tours in over 1,000 cities worldwide." />
        <meta name="twitter:image" content="https://sandbox.limowide.com/static/media/logo.ac9490d8ab0c66d9e30d.png" />
      </Helmet>
      <section className="banner d-flex align-items-center justify-content-center"> 
        <Col md={'9'} className='text-center col-11' style={{ zIndex: '1', position: "relative" }}>
          <h1 data-aos="zoom-in-up" data-aos-duration="1000"><span className=''>We simplify your Airport &</span> City Transfers Worldwide </h1>
          <p data-aos="zoom-in-up" data-aos-duration="1000" className='mb-4 text-white'>Arrive in style and comfort with our reliable airport transfer services, ensuring a smooth transition from terminal to destination. </p>
          {/* <Link  data-aos="zoom-in-up" data-aos-duration="1000" to='/' className="booknow d-inline-flex">Explore Now</Link> */}
        </Col>
      </section> 
      {/*  */}
      <Search />
      {/*  */}
      <section data-aos="fade-left" className='info_wrap bg_1' style={{ paddingTop: '90px', paddingBottom: '160px' }}>
        <Col xxl={'9'} xl={'9'} lg={'10'} md={'11'} className='col-11 mx-auto'>
          <div data-aos="zoom-in-up" data-aos-duration="2000"  className='text-center mb-4 pb-4 mb-lg-5 pb-lg-5'>
            <h2 className=''>WHY YOU SHOULD <br />CHOOSE US</h2>
          </div>
          {/*  */}
          <div className='position-relative overflow-hidden py-5'>
            <Row className='gx-5 mb-5 mb-lg-0'>
              <Col lg={'6'} className='mb-5 mb-lg-0 position-relative'>
                <div className='imgInfo'><img data-aos="flip-left" data-aos-duration="2000" className='img-fluid' src={infoImg} alt='icon' /></div>
              </Col>
              <Col lg={'6'} className=''>
                <div data-aos="fade-left" data-aos-duration="2000"  className='content mb-4'>
                  <h4 className='title mb-3'>Affordable Service Charges</h4>
                  <p className='mb-0'>Experience budget-friendly service charges without compromising on quality. Our transparent pricing ensures affordability without hidden fees. Enjoy cost-effective solutions tailored to your travel needs.</p>
                </div>
                <div  data-aos="fade-left" data-aos-duration="2000"  className='content'>
                  <h4 className='title mb-3'>WIDE RANGE OF CARS</h4>
                  <p className='mb-0'>Choose from our diverse fleet of vehicles tailored to suit your every need, whether it's a solo trip or a group excursion. From sleek sedans to spacious SUVs, we offer a wide range of cars to accommodate your preferences and travel requirements.</p>
                </div>
              </Col>
            </Row>
            {/*  */}
            <div className='position-relative overflow-hidden py-5'>
              <Row className='gx-5'>
                <Col lg={'6'} className='mb-5 mb-lg-0'>
                  <div data-aos="fade-right" data-aos-duration="2000"  className='content mb-4'>
                    <h4 className='title1 mb-3 text-lg-end text-start'>Dedication & Commitment</h4>
                    <p className='text-lg-end text-start mb-0'>With unwavering dedication and a commitment to excellence, we go the extra mile to exceed your expectations. Our team is devoted to delivering unparalleled service and ensuring your satisfaction every step of the way.</p>
                  </div>
                  <div data-aos="fade-right" data-aos-duration="2000"  className='content'>
                    <h4 className='title1 mb-3 text-lg-end text-start'>Ensured Security</h4>
                    <p className='text-lg-end text-start mb-0'>Your safety is paramount to us. Our comprehensive security protocols, experienced drivers, and well-maintained vehicles guarantee a secure journey. Rest assured, your peace of mind is our top priority.</p>
                  </div>
                </Col>
                <Col lg={'6'} className='position-relative'>
                  <div className='imgInfo'><img data-aos="flip-left" data-aos-duration="2000" className='img-fluid' src={infoImg1} alt='icon' /></div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </section>
      {/*  */}
      <section className='full mx-2 mb-5' style={{ paddingTop: '80px', paddingBottom: '80px', borderRadius: '48px', }}>
        <Col xxl={'10'} xl={'9'} lg={'10'} md={'11'} className='col-11 mx-auto'>
          <div data-aos="zoom-in-up" data-aos-duration="2000"  className='heading text-center'>
            <h3 className=''>TAKE A CLOSER LOOK <br />AT OUR SERVICES</h3>
          </div>
        </Col>
        {/*  */}
        <div data-aos="zoom-in-up" data-aos-duration="1000" className='servicesCads gx-0 my-4 my-lg-5 pb-lg-5'>
          <Swiper {...cardsprojects}>
            {CardsprojectsULArray.map((val) => {
              return (
                <SwiperSlide key={val.key}>
                  <CardsprojectsUL
                    id={val.key}
                    serviseIMG={val.serviseIMG}
                    cardColor={val.cardColor}
                    serviseIMGLogo={val.serviseIMGLogo}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        {/*  */}
        <Col xxl={'10'} xl={'9'} lg={'10'} md={'11'} className='col-11 mx-auto'>
          <Row className='coumn_wrap'>
            <Col data-aos="flip-left" data-aos-duration="1000" xxl={'3'} xl={'3'} lg={'6'} className='mb-5 mb-xl-0 pb-4 pb-xl-0 position-relative'>
              <span>01</span>
              <div className='px-lg-4'>
                <h5 className='mb-3'> AIRPORT TRANSFERS</h5>
                <p className='mb-3'>Our service is designed to ensure that every airport transfer is seamless, thanks to the inclusion of extra wait time and flight tracking to account for any potential delays.</p>
                <Link to={'/airport-transfer'} className='mainText' style={{ fontWeight: '600', }}>Read More.</Link>
              </div>
            </Col>
            <Col data-aos="flip-left" data-aos-duration="1000" xxl={'3'} xl={'3'} lg={'6'} className='mb-5 mb-xl-0 pb-4 pb-xl-0 position-relative'>
              <span>02</span>
              <div className='px-lg-4'>
                <h5 className='mb-3'> CHAUFEUR SERVICE</h5>
                <p className='mb-3'>Experience the superior service of a classic chauffeur, combined with the ease of getting a ride just minutes after making a booking.</p>
                <Link to={'/chauffeur-service'} className='mainText' style={{ fontWeight: '600', }}>Read More.</Link>
              </div>
            </Col>
            <Col data-aos="flip-right" data-aos-duration="1000" xxl={'3'} xl={'3'} lg={'6'} className='mb-5 mb-lg-0 pb-4 pb-lg-0 position-relative'>
              <span>03</span>
              <div className='px-lg-4'>
                <h5 className='mb-3'> CITY TOUR</h5>
                <p className='mb-3'>Introducing our stress-free solution for traveling within the city: Explore our self-guided and customized Limo city tour service.</p>
                <Link to={'/city-tour'} className='mainText' style={{ fontWeight: '600', }}>Read More.</Link>
              </div>
            </Col>
            <Col data-aos="flip-right" data-aos-duration="1000" xxl={'3'} xl={'3'} lg={'6'} className='position-relative'>
              <span>04</span>
              <div className='px-lg-4'>
                <h5 className='mb-3'> TICKET BOOKING</h5>
                <p className='mb-3'>Streamline your travel plans with our convenient ticket booking service, ensuring hassle-free reservations for your next adventure  tickets with just a few clicks.</p>
                <Link to="/" className='mainText' style={{ fontWeight: '600', }}>Read More.</Link>
              </div>
            </Col>
          </Row>
        </Col>
      </section>
      {/*  */}
      <section className='destinations' style={{ paddingTop: '80px', paddingBottom: '80px' }}>
        <Col xxl={'10'} xl={'9'} lg={'10'} md={'11'} className='col-11 mx-auto'>
          <div data-aos="zoom-in-up" data-aos-duration="2000" className='heading text-start mb-4 mb-lg-5 pb-lg-5'>
            <h5 className='secondaryText'>DISCOVER POPULAR <br />DESTINATIONS</h5>
          </div>
          {/*  */}
          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} className={'destinations_tabs'} default={"ltr"}>
            <TabList>
              <Tab onClick={() => { GetCitiesByContinent("popular") }}>POPULAR</Tab>
              <Tab onClick={() => { GetCitiesByContinent("antarctica") }}>ANTARCTICA</Tab>
              <Tab onClick={() => { GetCitiesByContinent("asia") }}>ASIA</Tab>
              <Tab onClick={() => { GetCitiesByContinent("africa") }}>AFRICA</Tab>
              <Tab onClick={() => { GetCitiesByContinent("europe") }}>EUROPE</Tab>
              <Tab onClick={() => { GetCitiesByContinent("south_america") }}>SOUTH AMERICA</Tab>
              <Tab onClick={() => { GetCitiesByContinent("north_america") }}>NORTH AMERICA</Tab>
              <Tab onClick={() => { GetCitiesByContinent("oceania") }}>OCEANIA</Tab>
            </TabList>
            <TabPanel>
              <Swiper {...optionsprojects} className='mt-5'>
                {isLoading ? (
                  <div className="flex justify-center items-center h-96">
                    <Loading />
                  </div>
                ) : (
                  <>
                    {cities.length > 0 ? (
                      cities.map((val, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <ExperienceUL
                              id={index}
                              title={val.page_title}
                              mainimg={val.thumbnail_image}
                              lcationtext={val.city_name + ', ' + val.country_name}
                              slug={val.slug}
                            />
                          </SwiperSlide>
                        );
                      })
                    ) : (
                      <div className="flex justify-center items-center h-96">
                        <p className="text-lg font-bold ">No Record Found!</p>
                      </div>
                    )}
                  </>
                )}
              </Swiper>
            </TabPanel>
            <TabPanel>
              <Swiper {...optionsprojects} className='mt-5'>
                {isLoading ? (
                  <div className="flex justify-center items-center h-96">
                    <Loading />
                  </div>
                ) : (
                  <>
                    {cities.length > 0 ? (
                      cities.map((val, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <ExperienceUL
                              id={index}
                              title={val.page_title}
                              mainimg={val.thumbnail_image}
                              lcationtext={val.city_name + ', ' + val.country_name}
                              slug={val.slug}
                            />
                          </SwiperSlide>
                        );
                      })
                    ) : (
                      <div className="flex justify-center items-center h-96">
                        <p className="text-lg font-bold ">No Record Found!</p>
                      </div>
                    )}
                  </>
                )}
              </Swiper>
            </TabPanel>
            <TabPanel>
              <Swiper {...optionsprojects} className='mt-5'>
                {isLoading ? (
                  <div className="flex justify-center items-center h-96">
                    <Loading />
                  </div>
                ) : (
                  <>
                    {cities.length > 0 ? (
                      cities.map((val, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <ExperienceUL
                              id={index}
                              title={val.page_title}
                              mainimg={val.thumbnail_image}
                              lcationtext={val.city_name + ', ' + val.country_name}
                              slug={val.slug}
                            />
                          </SwiperSlide>
                        );
                      })
                    ) : (
                      <div className="flex justify-center items-center h-96">
                        <p className="text-lg font-bold ">No Record Found!</p>
                      </div>
                    )}
                  </>
                )}
              </Swiper>
            </TabPanel>
            <TabPanel>
              <Swiper {...optionsprojects} className='mt-5'>
                {isLoading ? (
                  <div className="flex justify-center items-center h-96">
                    <Loading />
                  </div>
                ) : (
                  <>
                    {cities.length > 0 ? (
                      cities.map((val, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <ExperienceUL
                              id={index}
                              title={val.page_title}
                              mainimg={val.thumbnail_image}
                              lcationtext={val.city_name + ', ' + val.country_name}
                              slug={val.slug}
                            />
                          </SwiperSlide>
                        );
                      })
                    ) : (
                      <div className="flex justify-center items-center h-96">
                        <p className="text-lg font-bold ">No Record Found!</p>
                      </div>
                    )}
                  </>
                )}
              </Swiper>
            </TabPanel>
            <TabPanel>
              <Swiper {...optionsprojects} className='mt-5'>
                {isLoading ? (
                  <div className="flex justify-center items-center h-96">
                    <Loading />
                  </div>
                ) : (
                  <>
                    {cities.length > 0 ? (
                      cities.map((val, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <ExperienceUL
                              id={index}
                              title={val.page_title}
                              mainimg={val.thumbnail_image}
                              lcationtext={val.city_name + ', ' + val.country_name}
                              slug={val.slug}
                            />
                          </SwiperSlide>
                        );
                      })
                    ) : (
                      <div className="flex justify-center items-center h-96">
                        <p className="text-lg font-bold ">No Record Found!</p>
                      </div>
                    )}
                  </>
                )}
              </Swiper>
            </TabPanel>
            <TabPanel>
              <Swiper {...optionsprojects} className='mt-5'>
                {isLoading ? (
                  <div className="flex justify-center items-center h-96">
                    <Loading />
                  </div>
                ) : (
                  <>
                    {cities.length > 0 ? (
                      cities.map((val, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <ExperienceUL
                              id={index}
                              title={val.page_title}
                              mainimg={val.thumbnail_image}
                              lcationtext={val.city_name + ', ' + val.country_name}
                              slug={val.slug}
                            />
                          </SwiperSlide>
                        );
                      })
                    ) : (
                      <div className="flex justify-center items-center h-96">
                        <p className="text-lg font-bold ">No Record Found!</p>
                      </div>
                    )}
                  </>
                )}
              </Swiper>
            </TabPanel>
            <TabPanel>
              <Swiper {...optionsprojects} className='mt-5'>
                {isLoading ? (
                  <div className="flex justify-center items-center h-96">
                    <Loading />
                  </div>
                ) : (
                  <>
                    {cities.length > 0 ? (
                      cities.map((val, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <ExperienceUL
                              id={index}
                              title={val.page_title}
                              mainimg={val.thumbnail_image}
                              lcationtext={val.city_name + ', ' + val.country_name}
                              slug={val.slug}
                            />
                          </SwiperSlide>
                        );
                      })
                    ) : (
                      <div className="flex justify-center items-center h-96">
                        <p className="text-lg font-bold ">No Record Found!</p>
                      </div>
                    )}
                  </>
                )}
              </Swiper>
            </TabPanel>
            <TabPanel>
              <Swiper {...optionsprojects} className='mt-5'>
                {isLoading ? (
                  <div className="flex justify-center items-center h-96">
                    <Loading />
                  </div>
                ) : (
                  <>
                    {cities.length > 0 ? (
                      cities.map((val, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <ExperienceUL
                              id={index}
                              title={val.page_title}
                              mainimg={val.thumbnail_image}
                              lcationtext={val.city_name + ', ' + val.country_name}
                              slug={val.slug}
                            />
                          </SwiperSlide>
                        );
                      })
                    ) : (
                      <div className="flex justify-center items-center h-96">
                        <p className="text-lg font-bold ">No Record Found!</p>
                      </div>
                    )}
                  </>
                )}
              </Swiper>
            </TabPanel>
          </Tabs>
          {/*  */}
          <div className='mt-5 d-grid justify-content-center'>
            <Link to={'/destinations'} className='destinations-btn btn d-flex align-items-center justify-content-center'>View All Destinations</Link>
          </div>
        </Col>
      </section>
      {/*  */}
      <section className='feel_wrap m-2 py-5'>
        <Col xxl={'10'} xl={'10'} lg={'11'} className='mx-auto col-11' style={{ position: 'relative', zIndex: '1' }}>
          <div className='' >
            <h4 className='title mb-4' data-aos="zoom-in-up" data-aos-duration="1000">FEEL THE WORLD WITH <br /><span className='mainText'>LIMOWIDE</span></h4>
            <p className='text-white mb-4' data-aos="zoom-in-up" data-aos-duration="1000">Arrive in style and comfort with our reliable airport transfer <br />services, ensuring a smooth transition from terminal to destination. </p>
            <Link className='mt-5 registerNow-btn btn d-flex align-items-center justify-content-center' data-aos="zoom-in-up" data-aos-duration="1000" to='/register'>Register Now</Link>
          </div>
        </Col>
      </section>
      {/* <MyVerticallyCenteredModalChatHistory
        show={modalShowChatHistory}
        onHide={() => setModalShowChatHistory(false)}
      /> */}
    </main>
  );
};

export default Home;

// ChatHistory
// function MyVerticallyCenteredModalChatHistory(props) {
//   return (
//     <Modal {...props} size="lg" centered aria-labelledby="contained-modal-title-vcenter">
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter"> Modal heading </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className=" text-center">
//         <p>The Signals feature is not a part of the Free plan. Please upgrade to our 'Express' plan to access premium features like this.</p>
//       </Modal.Body>
//     </Modal >
//   );
// }