import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear the authentication token
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('last_session');
    localStorage.removeItem('userType');
    localStorage.removeItem("coordinates");
    
    // Redirect to the sign-in page
    navigate('/signin');
  }, [navigate]);

  return null; // This component doesn't need to render anything
};

export default Logout;